<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/recordAbnormal' }">异常记录</el-breadcrumb-item>
        <el-breadcrumb-item>检修计划</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <div class="form-title">
          基本信息
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="检修代号" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="来源：">
                <el-input v-model="form.source" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="form-device-info">
          <div class="form-title">设备信息</div>
          <div style="padding-left: 40px">
            <el-button @click="addDevice" type="text">添加设备</el-button>
          </div>
        </div>
        <div style="margin-bottom:20px;">
          <div v-if="form.aliasObj.id">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设备别名：">{{form.aliasObj.archivesName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备识别号：">{{form.aliasObj.materialsCode}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设施位置：">{{form.aliasObj.archivesName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="巡视点名称：">{{form.aliasObj.typeName}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设备名称：">{{form.aliasObj.archivesName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备类型：">{{form.aliasObj.typeName}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="所属部门：">{{form.aliasObj.departmentName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="规格参数：">{{form.aliasObj.specification}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="投入使用日期：" label-width="130px">
                 {{ form.aliasObj.createTime ? dayjs(form.aliasObj.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
                </el-form-item>
              </el-col>
              <el-col :span="12"></el-col>
            </el-row>
          </div>
        </div>
        <div class="form-title">
          检修人员配置<span style="color: red"> *</span>
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="检修人员">
              <div @click="addStaff()" class="staffBox">
                <div v-if="staffList.length>0">
                  <el-tag v-for="staff in staffList" :key="staff.id" style="margin-right: 4px;">
                    {{ staff.name }}
                  </el-tag>
                </div>
                <div v-else style="color: #909399;text-align: center;">请选择检修人员</div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <div class="form-title">
          问题文字描述<span style="color: red"> *</span>
        </div>
        <el-form-item label-width="20px" prop="desc">
          <el-input v-model="form.desc" type="textarea" :rows="2" style="width:700px" ></el-input>
        </el-form-item>
        <div class="form-title">
          检修方案（审核人员填）<span style="color: red"> *</span>
        </div>
        <el-form-item label-width="20px" prop="plan">
          <el-input v-model="form.plan" type="textarea" :rows="2" style="width:700px" ></el-input>
        </el-form-item>
        <div class="bottom-btn">
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
        </div>
      </el-form>
    </div>
    <el-drawer
      title="添加设备"
      :with-header="false"
      :visible.sync="deviceDrawer"
      direction="rtl"
      size="800px">
      <div class="drawer-title">添加设备</div>
      <div class="drawer-content">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="类型">
            <el-select
              size="small"
              v-model="formInline.deviceTypeId"
              clearable
              placeholder=""
              style="width: 160px">
              <el-option
                v-for="item in leixin"
                :label="item.name"
                :value="item.id"
                :key="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属部门">
            <el-select
              size="small"
              v-model="formInline.departmentId"
              clearable
              placeholder=""
              style="width: 160px">
              <el-option
                v-for="item in bumen"
                :label="item.name"
                :value="item.id"
                :key="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="负责人">
            <el-select
              size="small"
              v-model="formInline.staffId"
              clearable
              placeholder=""
              style="width: 160px">
              <el-option
                v-for="item in yuangong"
                :label="item.name"
                :value="item.id"
                :key="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmitDevice">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="tableDataCp">
          <el-table-column label="勾选" width="70">
            <template slot-scope="scope">
              <el-radio-group v-model="deviceRadio" @change="checkeChange(scope.row)">
                <el-radio :label="scope.row.id">{{''}}</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称"></el-table-column>
          <el-table-column prop="materialsCode" label="编码"></el-table-column>
          <el-table-column prop="deviceTypeName" label="分类"></el-table-column>
          <el-table-column prop="departmentName" label="所属部门"></el-table-column>
          <el-table-column prop="staffName" label="负责人"></el-table-column>
          <el-table-column prop="count" label="数量"></el-table-column>
          <el-table-column prop="useCount" label="用量"></el-table-column>
          <el-table-column label="选择数量" width="80">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.num"
                :controls="false"
                :min="0"
                :max="scope.row.count - scope.row.useCount"
                size="small"
                style="width: 60px"
                :disabled="scope.row.checked">
              </el-input-number>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="drawer-bottom">
        <el-button
          size="small"
          type="primary"
          @click="deviceDrawerOK"
          class="drawer-bottom-ok">确 定</el-button>
      </div>
    </el-drawer>
    <el-drawer
      title=""
      :with-header="false"
      :visible.sync="drawer"
      direction="rtl"
	  size="900px">
      <div class="drawer-title">选择巡检员</div>
      <div class="drawer-content" v-if="drawer">
        <div class="table-tree">
          <div class="tree">
            <div class="tree-title"><i class="el-icon-share"></i> 结构</div>
            <el-tree
              :data="treeData"
              @node-click="treeRowClick"
              :props="defaultProps"
              default-expand-all
              ref="tree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          
          <div class="table">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column width="40">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.check" :checked="staffListID.includes(scope.row.id)" @change="checked(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名"></el-table-column>
              <el-table-column prop="sex" label="性别">
                <template slot-scope="scope">
                  <span v-if="scope.row.sex == 1">男</span>
                  <span v-else>女</span>
                </template>
              </el-table-column>
              <el-table-column prop="departmentName" label="部门"></el-table-column>
              <el-table-column prop="name" label="岗位">
                <template slot-scope="scope">
                  <div
                    v-for="item in scope.row.positionInfoList"
                    :key="item.positionId"
                  >{{item.positionName}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="phone" label="电话"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="drawer-bottom">
        <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      form: {
        name: '',
        source: '新创建',
        aliasObj: {},
        desc: '',
        plan: ''
      },
      deviceRadio: '',
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        desc:[{ required: true, message: '必填项', trigger: 'blur' }],
        plan:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      props: {
        value: 'id',
        label: 'name',
        children: 'childTree'
      },
      row: {},
      options: [],
      value: [],
      patrolList: [], // 巡视点列表
      staffList: [], // 检修人员列表
      index: 1,
      // 添加设备初始变量
      deviceDrawer: false,
      formInline: {},
      bumen: [],
      leixin: [],
      yuangong: [],
      tableDataCp: [],
      tableSelectList: {},
      deviceTableData: [],
      staffId: 0,
      // 增加配置初始变量
      drawer: false,
      treeData: [],
      tableData: [],
      departmentTree: [],
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      checkedStaffList: [],
      staffListID: [],
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
    }
  },
  methods: {
    dayjs,
    // 添加设备
    addDevice() {
      this.deviceDrawer = true;
      this.tableDataCp = []
      this.deviceTableData.forEach(item => {
        let d = JSON.parse(JSON.stringify(item));
        d.num = 1
        d.checked = false
        this.tableDataCp.push(d)
      })
    },
    // 勾选设备
    checkeChange(row) {
      this.deviceRadio = row.id
      this.tableSelectList = row // 勾选
    },
    // 选择设备点击确定
    deviceDrawerOK(){
      if(!this.tableSelectList.id){
        this.$message.error('请选择设备！')
        return
      }
      this.$ajax.post("deviceDetail", { id: this.tableSelectList.id }).then((res) => {
        res.data.num = this.tableSelectList.num;
        res.data.alias = [];
        res.data.applyTime = [];
        this.form.aliasObj = res.data
      });
      this.deviceDrawer = false;
    },
    // 获取部门数据
    load_bumen() {
      this.$ajax.get("departmentTree").then((res) => {
        this.bumen = this.getTreeData(res.data);
      });
    },
    // 获取设备类型数据
    load_leixing() {
      this.$ajax.get("typeQueryAll").then((res) => {
        this.leixin = res.data;
      });
    },
    // 获取负责人数据
    load_yuangong() {
      this.$ajax.get("staffDueryAll").then((res) => {
        this.yuangong = res.data;
      });
    },
    // 遍历所有部门
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    // 查询数据
    onSubmitDevice(){
      this.loadListData();
    },
    // 获取设备列表数据
    loadListData() {
      this.$ajax.post("deviceChecklist", {
        departmentId: this.formInline.departmentId,
        deviceTypeId: this.formInline.deviceTypeId,
        staffId: this.formInline.staffId,
      }).then((res) => {
        this.deviceTableData = res.data;
        this.tableDataCp = []
        this.deviceTableData.forEach(item => {
          let d = JSON.parse(JSON.stringify(item));
          d.num = 1
          d.checked = false
          this.tableDataCp.push(d)
        })
      });
    },
    // 选择巡检员
    addStaff() {
      this.loadTableData()
      this.staffListID = []
      this.staffList.forEach(item => {
        this.staffListID.push(item.id)
      })
      this.checkedStaffList = [].concat(this.staffList)

      this.drawer = true
    },
    // 获取部门结构树
    loadTreeData() {
      this.$ajax.get('departmentTree').then(res => {
        this.treeData = res.data
      })
    },
    // 获取部门人员
    loadTableData(id) {
      this.$ajax.post('queryFilter', {
        departmentId: id ? id : 0
      }).then(res => {
        this.tableData = res.data
      })
    },
    // 选择部门结构
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    // 勾选巡检员
    checked(row) {
      if (!this.staffListID.includes(row.id)) {
        this.checkedStaffList.push(row)
        this.staffListID.push(row.id)
      } else {
        this.checkedStaffList.forEach((item, index) => {
          if (item.id == row.id) {
            this.checkedStaffList.splice(index, 1)

            this.$delete(this.staffListID, index)
            console.log(this.staffListID)
          }
        })
      }
    },
    // 选择巡检员点击确定
    drawerOK() {
      this.drawer = false
      this.staffList = [].concat(this.checkedStaffList)
      this.staffListID = [].concat(this.staffListID)
    },
    // 提交任务
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let fObj =  this.form.aliasObj
          let form = {
            baseInfo: {
              order: this.form.name,
              source: 0
            },
            deviceInfo: {
              alias: fObj.archivesName, // 设备别名
              applyTime: fObj.createTime, // 投入使用日期
              departmentId: fObj.departmentId, // 部门Id
              departmentName: fObj.departmentName, // 所属部门
              deviceId: fObj.id, // 设备Id
              deviceName: fObj.archivesName, // 设备名称
              deviceTypeId: fObj.typeId,
              deviceTypeName: fObj.typeName, // 分类
              facilityId: 0, // 设施Id
              facilityName: '车间', // 设施位置
              patrolDeviceId: 0, // 巡视点名称Id
              patrolId: fObj.staffId, // 负责人Id
              patrolName: '一级巡视点', // 巡视点名称
              specification: fObj.specification // 规格参数
            },
            elementInfo: {
              description: this.form.desc,
              plan: this.form.plan,
              staffInfos: []
            }
          } 
          this.staffList.forEach((item)=>{
            form.elementInfo.staffInfos.push({
              staffId: item.id,
              staffName: item.name
            })
          })
          if (fObj.id==null) {
            this.$message.error("设备信息不能为空！");
            return
          }
          if (form.elementInfo.staffInfos.length == 0) {
            this.$message.error("人员配置不能为空！");
            return
          }
          this.$ajax.post("reconditionTaskCreate", form).then((res) => {
            this.$message.success("成功");
            this.$router.push("/overhaulTask");
          })
        }
      })
    },
    // 获取巡视点数据
    loadPatrolList() {
      this.$ajax.post("patrolList", {
        facilityId: ''
      }).then((res) => {
        this.patrolList = res.data;
      });
    },
    // 获取巡检员
    loadStaffList() {
      this.$ajax.get("staffDueryAll").then((res) => {
        // this.staffList = res.data;
      });
    },
    loadDepartmentTree() {
      this.$ajax.get("departmentTree").then((res) => {
        this.departmentTree = res.data;
      });
    }
  },
  mounted() {
    // 添加设备初始数据
    this.load_bumen()
    this.load_leixing()
    this.load_yuangong()
    this.loadListData()
    // 增加配置初始数据
    this.loadTreeData()
    this.loadPatrolList()
    this.loadStaffList()
    this.loadDepartmentTree()
    this.staffId = sessionStorage.getItem('id')
    if (this.$route.params.row == null) {
        this.$router.push('/recordAbnormal')
    } else {
        this.row = this.$route.params.row
        this.form.source = this.row.taskType==0 ? '巡检' : '点检'
        console.log(this.row)
    }
  }
}
</script>

<style lang="less" scoped>
.form-box {
  max-width: 1000px;
}
.drawer-content {
  height: 100%;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  background: #fff;
  margin-right: 20px;
  padding: 4px;
  min-height: 40px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
}
.checkbox {
  margin-bottom: 20px;
  padding-left: 40px;
}
.form-device-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>